import { gql } from "@apollo/client";

export const CREATE_VENUE = gql`
  mutation createVenue($venue: VenueInput!) {
    createVenue(venue: $venue) {
      id
    }
  }
`;

export const UPDATE_VENUE = gql`
  mutation updateVenue($id: String!, $venue: VenueInput!) {
    updateVenue(id: $id, venue: $venue) {
      id
    }
  }
`;

export const DELETE_VENUE = gql`
  mutation deleteVenue($id: String!) {
    deleteVenue(id: $id)
  }
`;

export const CREATE_VENDOR = gql`
  mutation createVendor($vendor: VendorInput!) {
    createVendor(vendor: $vendor) {
      _id
    }
  }
`;

export const UPDATE_VENDOR = gql`
  mutation updateVendor($id: String!, $vendor: VendorInput!) {
    updateVendor(id: $id, vendor: $vendor) {
      _id
    }
  }
`;

export const DELETE_VENDOR = gql`
  mutation deleteVendor($id: String!) {
    deleteVendor(id: $id)
  }
`;

export const CREATE_RUNNER = gql`
  mutation createRunner($runner: RunnerInput!) {
    createRunner(runner: $runner) {
      _id
    }
  }
`;

export const UPDATE_RUNNER = gql`
  mutation updateRunner($id: String!, $runner: RunnerInput!) {
    updateRunner(id: $id, runner: $runner) {
      _id
    }
  }
`;

export const DELETE_RUNNER = gql`
  mutation deleteRunner($id: String!) {
    deleteRunner(id: $id)
  }
`;

export const CREATE_ADMIN = gql`
  mutation createAdmin($admin: AdminInput!) {
    createAdmin(admin: $admin) {
      _id
    }
  }
`;

export const UPDATE_ADMIN = gql`
  mutation updateAdmin($id: String!, $admin: AdminInput!) {
    updateAdmin(id: $id, admin: $admin) {
      _id
    }
  }
`;

export const DELETE_ADMIN = gql`
  mutation deleteAdmin($id: String!) {
    deleteAdmin(id: $id)
  }
`;

export const UPDATE_CHARGES = gql`
  mutation updateCharges($charges: ChargesInput!) {
    updateCharges(charges: $charges) {
      markupCharge
      seatDeliveryCharge
      collectionPointDeliveryCharge
      serviceCharge
    }
  }
`;

export const UPDATE_CONFIG_CHARGES = gql`
mutation updateConfigCharges($context:AppConfigContext!, $chargesInput: ChargesInput!) {
  updateConfigCharges(context:$context, chargesInput:$chargesInput)
  
}
`

export const ASSIGN_RUNNER_FOR_PATRON_ORDER = gql`
  mutation assignRunnerForPatronOrder($id: String!, $runnerID: String!) {
    assignRunnerToPatronOrder(id: $id, runnerID: $runnerID) {
      _id
    }
  }
`;

export const ASSIGN_RUNNER_FOR_VENDOR_ORDER = gql`
  mutation assignRunnerForVendorOrder($id: String!, $runnerID: String!) {
    assignRunnerToVendorOrder(id: $id, runnerID: $runnerID) {
      _id
    }
  }
`;
export const ASSIGN_RUNNER_FOR_VENDOR_ORDER_LIST = gql`
  mutation assignRunnerForVendorOrdersList(
    $payload: VendorOrderAssignmentInput
  ) {
    assignRunnersToVendorOrdersList(payload: $payload) {
      _id
    }
  }
`;

export const ADD_DEVICE = gql`
  mutation addDeviceToUser($input: AddDeviceToUserInput!) {
    addDeviceToUser(input: $input)
  }
`;

export const CLEAR_DEVICE = gql`
  mutation clearDeviceFromUser($input: ClearDeviceFromUserInput!) {
    clearDeviceFromUser(input: $input)
  }
`;

export const MARK_AS_SEEN = gql`
  mutation seenNotifications($ids: [String]) {
    seenNotifications(ids: $ids)
  }
`;

export const DUPLICATE_VENDOR = gql`
  mutation duplicateVendor($originalVendorId: String!, $vendor: VendorInput!) {
    duplicateVendor(originalVendorId: $originalVendorId, vendor: $vendor) {
      _id
    }
  }
`;

export const CREATE_REFUND = gql`
  mutation createRefund($refund: RefundInput!) {
    createRefund(refund: $refund) {
      _id
    }
  }
`;

export const UPDATE_REFUND = gql`
  mutation updateRefund($id: String!, $refundInput: RefundInput!) {
    updateRefund(id: $id, refundInput: $refundInput) {
      _id
    }
  }
`;

export const ACCEPT_REFUND_REQUEST = gql`
  mutation acceptRefundRequest(
    $id: String!
    $acceptRefundRequestInput: AcceptRefundRequestInput!
  ) {
    acceptRefundRequest(
      id: $id
      acceptRefundRequestInput: $acceptRefundRequestInput
    ) {
      _id
    }
  }
`;

export const DELETE_REFUND = gql`
  mutation deleteRefund($id: String!) {
    deleteRefund(id: $id) {
      _id
    }
  }
`;

export const UPDATE_RUNNER_WITH_SECTIONS_AND_STATUS = gql`
  mutation updateRunnerWithSectionsAndStatus($id: String!, $runner: RunnerWithSectionsAndStatusInput!) {
    updateRunnerWithSectionsAndStatus(id: $id, runner: $runner) {
      _id
    }
  }
`;