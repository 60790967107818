import React, { useState } from "react";
import {
  FormControl,
  Grid,
  Box,
  Switch,
  Stepper,
  Step,
  StepLabel,
  Button,
  Snackbar,
  Tooltip,
} from "@mui/material";
import { CustomButton } from "../elements/button";
import { CustomInput } from "../elements/input";
import { VenueSections } from "../components/venue-form/sections";
import {
  CollectionPoint,
  Currency,
  DeliveryArea,
  Vendor,
  VenueMutation,
  VenueQuery,
  VenueSection,
  VenueTypeConfig,
} from "../types";
import { venueDetailsSchema, venueImageUrlSchema } from "./validations/venue";
import { Text } from "../elements/text";
import { CustomSelect, MenuItemType } from "../elements/select";
import { GET_BASIC_ACCOUNT_DETAILS, GET_CURRENCIES } from "../graphql/query";
import { useQueryHook } from "../hooks/use-query.hook";
import { v4 } from "uuid";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { VenueImageCard } from "../components/venue-form/image-card";
import { CollectionPoints } from "../components/venue-form/collection-points";
import { previewImages } from "../constants/images";
import { venueTypeConfig } from "../constants/constants";
import { Loader } from "../elements/loader";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSnackbar } from "notistack";
import { getVenueUrl } from "../utils/get-venue-url";
import { DeliveryAreas } from "../components/venue-form/delivery-areas";
import { FormToolTip } from "../elements/form-tooltip";
interface Props {
  // eslint-disable-next-line no-unused-vars
  onSubmit?: (data: VenueMutation) => void;
  data?: VenueQuery;
  submitButtonText?: string;
  isEdit: boolean;
  toolTipLabel: string;
}

export const VenueForm = (props: Props) => {
  const { data = { getCurrencies: [] }, loading: currenciesLoading } =
    useQueryHook({
      query: GET_CURRENCIES,
    });

  const { data: accounts, loading: accountsLoading } = useQueryHook({
    query: GET_BASIC_ACCOUNT_DETAILS,
  });

  const isCollectionPointEnabled = true; //hard-coded for now, flag to be decided
  const accountSelectData =
    accounts &&
    accounts.getVendors &&
    accounts.getVendors
      .filter((account: Partial<Vendor>) => !account.isDisabled)
      .map(
        (account: {
          _id: string;
          tradingName: string;
          isDisabled: boolean;
        }) => {
          if (account.isDisabled) return;
          const option: MenuItemType = {
            value: account._id,
            label: account.tradingName,
          };
          return option;
        }
      );
  const currencySelectDataMap = data.getCurrencies.map((currency: Currency) => {
    const menuItem: MenuItemType = {
      value: currency.name as string,
      label: `${currency.name} (${currency.symbol})`,
    };
    return menuItem;
  });
  const currencySelectData = currencySelectDataMap.filter(
    (menuItem: MenuItemType, ind: number) => {
      return currencySelectDataMap.indexOf(menuItem) == ind;
    }
  );

  const stripePaymentCaptureData: MenuItemType[] = [
    { value: "ACCEPTED", label: "On order accepted" },
    { value: "PREPARED", label: "On order prepared" },
  ];

  const venueTypeSelectData = venueTypeConfig.map(
    (venueType: VenueTypeConfig) => {
      const menuItem: MenuItemType = {
        value: venueType.name,
        label: venueType.label,
      };
      return menuItem;
    }
  );

  const [name, setName] = useState(props.data?.name);
  const [venueCode, setVenueCode] = useState(props.data?.venueCode);
  const [businessTaxNumber, setBusinessTaxNumber] = useState(
    props.data?.businessTaxNumber
  );
  const [address, setAddress] = useState(props.data?.address?.addressLine1);
  const [venueMapImageUrl, setVenueMapImageUrl] = useState(
    props.data?.venueMapImageUrl
  );
  const [ttsBrandingImageUrl, setTtsBrandingImageUrl] = useState(
    props.data?.ttsBrandingImageUrl
  );
  const [cncBrandingImageUrl, setCncBrandingImageUrl] = useState(
    props.data?.cncBrandingImageUrl
  );
  const [vendorListImageUrl, setVendorListImageUrl] = useState(
    props.data?.vendorListImageUrl
  );
  const [patronNavigationImageUrl, setPatronNavigationImageUrl] = useState(
    props.data?.patronNavigationImageUrl
  );
  const [printReceiptImageUrl, setPrintReceiptImageUrl] = useState(
    props.data?.printReceiptImageUrl
  );
  const [disabled, setDisabled] = useState(props.data?.isDisabled);
  const [runnerOrderAssignmentAutomation, setRunnerOrderAssignmentAutomation] = useState(props.data?.runnerOrderAssignmentAutomation);
  const [venueCurrency, setVenueCurrency] = useState(props.data?.venueCurrency);
  const [stripePaymentCapture, setStripePaymentCapture] = useState(
    props.data?.orderSettings?.capturePaymentByOrderStatus
  );

  const [venueType, setVenueType] = useState(props.data?.venueType);
  const [venueUrl, setVenueUrl] = useState(
    getVenueUrl(props.data?.venueCode || "")
  );

  const [minimumOrderPrice, setMinimumOrderPrice] = useState(
    props.data?.minimumOrderPrice
  );
  const [fixedServiceCharge, setFixedServiceCharge] = useState(
    props.data?.fixedServiceCharge
  );
  
  const [fixedVendorDeliveryCharge, setFixedVendorDeliveryCharge] = useState(
    props.data?.fixedVendorDeliveryCharge
  );
  const [sections, setSections] = useState<VenueSection[] | undefined>(
    props?.data?.sections
  );

  const [deliveryAreas, setDeliveryAreas] = useState<DeliveryArea[] | undefined > (props?.data?.deliveryAreas)
  const [collectionPoints, setCollectionPoints] = useState<
    CollectionPoint[] | undefined
  >(props?.data?.collectionPoints);
  const [accountID, setAccountID] = useState<string | undefined>(
    props?.data?.accountID
  );


  const [error, setError] = useState("");
  const [activeStep, setActiveStep] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { onSubmit = () => undefined } = props;
    if (validateImageUrls() && validateDetails()) {
      onSubmit({
        name: name || "",
        venueCode: venueCode || "",
        sections: sections || [],
        collectionPoints: collectionPoints || [],
        deliveryAreas: deliveryAreas || [],
        address: {
          addressLine1: address || "",
          addressLine2: "",
          country: "",
          postlCode: "",
          state: "",
        },
        venueMapImageUrl: venueMapImageUrl || "",
        ttsBrandingImageUrl: ttsBrandingImageUrl || "",
        cncBrandingImageUrl: cncBrandingImageUrl || "",
        vendorListImageUrl: vendorListImageUrl || "",
        patronNavigationImageUrl: patronNavigationImageUrl || "",
        printReceiptImageUrl:printReceiptImageUrl || "",
        isDisabled: disabled || false,
        runnerOrderAssignmentAutomation: runnerOrderAssignmentAutomation || false,
        venueType: venueType || "",
        venueCurrency: venueCurrency || {
          _id: v4(),
          name: "AUD",
          stripeCode: "aud",
          symbol: "$",
        },
        minimumOrderPrice: minimumOrderPrice || 0,
        fixedServiceCharge: fixedServiceCharge || 0,
        fixedVendorDeliveryCharge: fixedVendorDeliveryCharge || 0,
        accountID: accountID || "",
        capturePaymentByOrderStatus: stripePaymentCapture || "",
        businessTaxNumber: businessTaxNumber || "",
      });
    }
  };

  const validateDetails = () => {
    const { error } = venueDetailsSchema.validate({
      name: name?.trim(),
      address: address?.trim(),
      sections: sections,
      accountID: accountID,
      venueType: venueType,
      venueCurrency: venueCurrency,
      collectionPoints: collectionPoints,
      minimumOrderPrice: minimumOrderPrice,
      fixedServiceCharge: fixedServiceCharge,
      patronNavigationImageUrl: patronNavigationImageUrl,
      stripePaymentCapture: stripePaymentCapture,
    });

    if (!error) {
      setError("");
      return true;
    }
    setError(error.message);
    return false;
  };

  const validateImageUrls = () => {
    const { error } = venueImageUrlSchema.validate({
      venueMapImageUrl: venueMapImageUrl,
      ttsBrandingImageUrl: ttsBrandingImageUrl,
      cncBrandingImageUrl: cncBrandingImageUrl,
      vendorListImageUrl: vendorListImageUrl,
      printReceiptImageUrl:printReceiptImageUrl
    });

    if (!error) {
      setError("");
      return true;
    }
    setError(error.message);
    return false;
  };

  const steps = ["Fill details", "Venue Setup"];

  const handleNext = () => {
    if (validateDetails()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  if (currenciesLoading || accountsLoading || accounts === undefined)
    return <Loader />;

  return (
    <form onSubmit={submit}>
      <Stepper activeStep={activeStep} sx={{ mt: 2 }}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <Grid container spacing={2} mt={2}>
        {activeStep === 0 && (
          <>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <CustomInput
                  required
                  onChange={setName}
                  title="Name"
                  value={name}
                  isMandatory={true}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  required
                  onChange={setAddress}
                  title="Address"
                  value={address}
                  isMandatory={true}
                />
              </Grid>

              <Grid item xs={12}>
                <CustomSelect
                  required
                  disabled={props.isEdit ? true : false}
                  data={accountSelectData}
                  onChange={(e) => {
                    setAccountID(e);
                  }}
                  title="Account"
                  value={accountID}
                  isMandatory={true}
                />
              </Grid>

              <Grid item xs={12}>
                <CustomSelect
                  disabled={props.isEdit ? true : false}
                  data={venueTypeSelectData}
                  onChange={(e) => {
                    setVenueType(e);
                  }}
                  title="Venue Type"
                  value={venueType}
                  isMandatory={true}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomSelect
                  data={currencySelectData}
                  onChange={(e) => {
                    const newCurrency = data.getCurrencies.find(
                      (curr: Currency) => curr.name === e
                    );
                    setVenueCurrency(newCurrency);
                  }}
                  title="Venue Currency"
                  value={venueCurrency?.name}
                  isMandatory={true}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomSelect
                  data={stripePaymentCaptureData}
                  onChange={(e: string) => setStripePaymentCapture(e)}
                  title="Stripe payment capture"
                  value={stripePaymentCapture}
                  isMandatory={true}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  required
                  onChange={(val) => {
                    setMinimumOrderPrice(val);
                  }}
                  title="Minimum Order Value"
                  value={minimumOrderPrice}
                  helperText={"Enter the price for minimum order"}
                  type={"number"}
                />
              </Grid>

              <Grid item xs={12}>
                <CustomInput
                  required
                  onChange={(val) => setFixedServiceCharge(val)}
                  title="Fixed Service Charge"
                  value={fixedServiceCharge}
                  helperText={"Enter the fixed service charge on minimum order"}
                  type={"number"}
                />
              </Grid>
              {venueType==("DINE_IN")? (<Grid item xs={12}>
                <CustomInput
                  required
                  onChange={(val) => setFixedVendorDeliveryCharge(val)}
                  title="Fixed Vendor Delivery Charge"
                  value={fixedVendorDeliveryCharge}
                  isMandatory={true}
                  helperText={"Enter the price for vendor delivery charge"}
                  type={"number"}
                />
              </Grid>) : "" }
              
              <Grid item xs={12}>
                <CustomInput
                  required
                  onChange={(value: string) => {
                    setVenueCode(value);
                    setVenueUrl(getVenueUrl(value));
                  }}
                  title="Venue Code"
                  value={venueCode}
                  helperText={"Allowed characters: alphanumeric, hyphen(-)"}
                  placeHolder={"Ex: VENUE-NAME-01"}
                />
              </Grid>

              <Grid item xs={12} fontSize={24} fontWeight={500}>
                <Text text={"Venue URL"} />
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  marginLeft={"4px"}
                >
                  <Text text={venueUrl} fontSize={16} fontWeight={100} />

                  <Box sx={{ cursor: "pointer", marginX: "5px" }}>
                    <Tooltip title="Copy URL">
                      <ContentCopyIcon
                        onClick={() => {
                          navigator.clipboard.writeText(venueUrl);
                          enqueueSnackbar("URL Copied to Clipboard!", {
                            autoHideDuration: 3000,
                            variant: "success",
                          });
                        }}
                      />
                    </Tooltip>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <VenueSections
                    onChange={setSections}
                    title="Sections"
                    sections={sections}
                    isMandatory={true}
                    collectionPoints={collectionPoints || []}
                    deliveryAreas = {deliveryAreas || []}
                    setCollectionPoints={setCollectionPoints}
                    setDeliveryAreas = {setDeliveryAreas}
                    venueType = {venueType || ''}
                  />
                </FormControl>
              </Grid>

              {venueType==("EVENT") && isCollectionPointEnabled ? (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <CollectionPoints
                      onChange={setCollectionPoints}
                      title="Collection Points"
                      collectionPoints={collectionPoints}
                      venueSections={
                        sections?.filter((section) => !section.isDeleted) || []
                      }
                    />
                  </FormControl>
                </Grid>
              ) : (
                ""
              )}

              {venueType==("DINE_IN") ? (
                <Grid item xs={12}>
                <FormControl fullWidth>
                  <DeliveryAreas
                    onChange={setDeliveryAreas}
                    title="Delivery Areas"
                    deliveryAreas={deliveryAreas}
                    isMandatory={true}
                    venueSections={
                      sections?.filter((section) => !section.isDeleted) || []
                    }
                  />
               </FormControl>
               </Grid>
              ) : (
                "" 
              )}
              
              <VenueImageCard
                previewImageSrc={patronNavigationImageUrl}
                label={"Venue Image"}
                toolTipLabel={
                  "This is used when no image is present in Section/CollectionPoint (for CNC and CP orders only)"
                }
                resourceImageSrc={patronNavigationImageUrl}
                onUploadImage={setPatronNavigationImageUrl}
                venueName={name}
                resourceImageNote={
                  "This is used when no image is present in Section/CollectionPoint (for CNC and CP orders only)"
                }
              />
              <Grid item xs={12}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                  >
                    <span>Tax Information</span>
                    <FormToolTip text="This number will be printed in the receipt" />
                  </div>
                  <CustomInput
                    required
                    onChange={(value: string) => {
                      setBusinessTaxNumber(value);
                    }}
                    value={businessTaxNumber}
                    helperText={"Up to 20 characters allowed"}
                    placeHolder={"Ex: TRN:1234"}
                  />
                </div>
              </Grid>
              {venueType == "EVENT" ? (
                <Grid item xs={12}>
                  <Box display={"flex"} alignItems={"center"}>
                    <Text text={"Runner Order Assignment Automation"}></Text>
                    <Switch
                      checked={runnerOrderAssignmentAutomation}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setRunnerOrderAssignmentAutomation(e.target.checked);
                      }}
                    />
                  </Box>
                </Grid>
              ) : (
                ""
              )}
              <Grid item xs={12}>
                <Box display={"flex"} alignItems={"center"}>
                  <Text text={"Disable the venue"}></Text>
                  <Switch
                    checked={disabled}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setDisabled(e.target.checked);
                    }}
                  />
                </Box>
              </Grid>
              <Box
                display={"flex"}
                alignItems={"center"}
                textAlign={"center"}
                marginTop={"1vh"}
                ml={"0.7vw"}
              >
                <Text
                  style={{ marginLeft: 1, marginRight: 1 }}
                  text=" * "
                  fontSize={15}
                  color={"red"}
                />
                <Text text="indicates a required field" fontSize={15} />
              </Box>
              <Grid item xs={12}>
                <Box
                  height={"1vh"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent="center"
                >
                  {error && (
                    <Text text={error} fontSize={15} style={{ color: "red" }} />
                  )}
                </Box>
              </Grid>
            </Grid>
          </>
        )}

        {activeStep === steps.length - 1 && (
          <Grid
            container
            item
            spacing={2}
            borderRadius={"8px"}
            mt={2}
            sx={{ backgroundColor: "#e8e8e8bb" }}
            paddingRight={"16px"}
            paddingBottom={"16px"}
          >
            <Text text="Images" fontSize={24} fontWeight={600} />

            <VenueImageCard
              previewImageSrc={previewImages.PREVIEW_VENUE_MAP}
              label={"Venue Map"}
              isMandatory={true}
              toolTipLabel={"This is used on the map section of runner app"}
              resourceImageSrc={venueMapImageUrl}
              onUploadImage={setVenueMapImageUrl}
              venueName={name}
              resourceImageNote={
                "This is the image displayed on the runner app under the map section"
              }
            />

            <VenueImageCard
              previewImageSrc={previewImages.PREVIEW_TTS}
              label={"TTS Branding (landing page)"}
              toolTipLabel={"This is used on the home page of patron app"}
              resourceImageSrc={ttsBrandingImageUrl}
              onUploadImage={setTtsBrandingImageUrl}
              venueName={name}
              resourceImageNote={
                "This is the image displayed for the patron when this Venue is selected and the section is To The Seat (TTS) delivery."
              }
            />

            <VenueImageCard
              previewImageSrc={previewImages.PREVIEW_CNC}
              label={"CNC Branding (landing page)"}
              toolTipLabel={"This is used on the home page of patron app"}
              resourceImageSrc={cncBrandingImageUrl}
              onUploadImage={setCncBrandingImageUrl}
              venueName={name}
              resourceImageNote={
                "This is the image displayed for the patron when this Venue is selected and if the section is Click & Collect (CNC) delivery."
              }
            />

            <VenueImageCard
              previewImageSrc={previewImages.PREVIEW_VENDOR_BANNER}
              label={"Vendor List Image"}
              toolTipLabel={
                "This is used on the vendor list page of patron app"
              }
              resourceImageSrc={vendorListImageUrl}
              onUploadImage={setVendorListImageUrl}
              venueName={name}
              resourceImageNote={
                "This is the image used for branding on the vendor list page of patron app."
              }
              imageAspectRatio={3 / 1}
            />
            <VenueImageCard
              previewImageSrc={previewImages.PREVIEW_VENDOR_BANNER}
              label={"Print Receipt Image"}
              toolTipLabel={
                "This image is displayed on the printed receipt."
              }
              resourceImageSrc={printReceiptImageUrl}
              onUploadImage={setPrintReceiptImageUrl}
              venueName={name}
              resourceImageNote={
                "This image is displayed on the printed receipt."
              }
              optionalImageNote={"Width of the image should be less than 1000 in resolution"}
              imageAspectRatio={3 / 1}
            />
          </Grid>
        )}
        {activeStep === steps.length - 1 && (
          <Grid item xs={12}>
            <Box
              mt={1}
              display={"flex"}
              alignItems={"center"}
              justifyContent="center"
            >
              <Text
                text="Please ensure uploaded image size is less than 1 MB (.JPG, .PNG Only)"
                fontSize={14}
                color={"#777777"}
              />
            </Box>
            <CustomButton
              style={{ marginTop: "1vh" }}
              title={props.submitButtonText || "Create"}
            />
            <Box
              height={"1vh"}
              mt={2}
              display={"flex"}
              alignItems={"center"}
              justifyContent="center"
            >
              {error && (
                <Text text={error} fontSize={15} style={{ color: "red" }} />
              )}
            </Box>
          </Grid>
        )}

        <Grid item xs={12} display={"flex"} justifyContent="space-between">
          <Button onClick={handleBack} disabled={activeStep === 0}>
            {<KeyboardArrowLeft />} Back
          </Button>
          <Button
            onClick={handleNext}
            disabled={activeStep === steps.length - 1}
          >
            Next {<KeyboardArrowRight />}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};